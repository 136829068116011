
import firebase from "firebase/app";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { awaitStudentLoaded } from "@/store/index";
import { Student } from "@/entities/student";
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import MTextField from "@/components/form/MTextField.vue";
import MButton from "@/components/form/MButton.vue";
import { signOut } from "@/api/auth";

@Options({
  components: {
    MSInfoItem,
    MTextField,
    MButton
  }
})
export default class ResetPassword extends Vue {
  student: Student | null = null;
  currentPassword = "";
  newPassword = "";
  newPasswordToConfirm = "";
  currentPasswordInvalidMessage = "";
  newPasswordInvalidMessage = "";
  newPasswordToConfirmInvalidMassage = "";
  useSchoolAiExclusively = false;
  from = "";

  get validForm(): boolean {
    if (
      !this.currentPassword ||
      !this.newPassword ||
      !this.newPasswordToConfirm
    )
      return false;

    if (this.newPassword !== this.newPasswordToConfirm) return false;

    for (const pw of [
      this.currentPassword,
      this.newPassword,
      this.newPasswordToConfirm
    ]) {
      if (!this.validatePassword(pw)) {
        return false;
      }
    }

    return true;
  }

  get redirectTo(): string {
    const url = window.location.href;
    if (url.includes("localhost")) {
      return "http://localhost:3000/login_for_student";
    } else if (url.includes("mingaku-st")) {
      return "https://school-ai-system-stg.web.app/login_for_student";
    } else if (url.includes("mingaku-jp")) {
      return "https://school-ai-system.web.app/login_for_student";
    }
    return "/";
  }

  onInputPassword(val: string) {
    this.currentPassword = val;
  }

  onInputNewPassword(val: string) {
    this.newPassword = val;
  }

  onInputNewPasswordToConfirm(val: string) {
    this.newPasswordToConfirm = val;
  }

  validatePassword(pw: string) {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(pw);
  }

  validateInputPassword() {
    if (!this.validatePassword(this.currentPassword)) {
      this.currentPasswordInvalidMessage =
        "パスワードは半角英数字記号8文字以上で入力してください";
    } else {
      this.currentPasswordInvalidMessage = "";
    }
  }

  validateInputNewPassword() {
    if (!this.validatePassword(this.newPassword)) {
      this.newPasswordInvalidMessage =
        "新しいパスワードは半角英数字記号8文字以上で入力してください";
    } else {
      this.newPasswordInvalidMessage = "";
    }
  }

  validateInputPasswordToConfirm() {
    if (!this.validatePassword(this.newPasswordToConfirm)) {
      this.newPasswordToConfirmInvalidMassage =
        "新しいパスワードは半角英数字記号8文字以上で入力してください";
      return;
    } else if (
      this.newPassword.length > 0 &&
      this.newPassword !== this.newPasswordToConfirm
    ) {
      this.newPasswordToConfirmInvalidMassage =
        "確認用パスワードが一致しません。";
    } else {
      this.newPasswordToConfirmInvalidMassage = "";
    }
  }

  async commit() {
    if (!this.validForm) {
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "パスワード更新中...");
      const data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      };
      const call = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("student_reset_password");
      const res = await call(data);
      if (res.data.status !== "success") {
        alert("予期せぬエラーが発生しました。");
        return;
      }
      alert("パスワードを更新しました。");
      store.commit("SET_LOAD_TEXT", "リダイレクトしています...");
      await signOut();
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      store.commit("SIGN_OUT");

      if (this.useSchoolAiExclusively) {
        window.location.href = this.redirectTo;
      } else {
        if (this.from === "school_ai") {
          window.location.href = this.redirectTo;
        } else {
          this.$router.replace("/login");
        }
      }
    } catch (e) {
      alert("パスワードの更新に失敗しました。入力内容を再度確認してください。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  async created() {
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "");
      await awaitStudentLoaded(store);
      this.student = store.state.student;
      this.useSchoolAiExclusively =
        store.state.schoolConfig?.data.useSchoolAiExclusively ?? false;
      const allowStudentsToChangePassword =
        store.state.schoolConfig?.data.allowStudentsToChangePassword ?? false;
      if (!allowStudentsToChangePassword) {
        this.$router.push("/");
      }
      this.from = this.$route.params.from
        ? (this.$route.params.from as string)
        : "";
    } catch (e) {
      alert("エラーが発生しました。時間をおいて再度試してみてください。");
      console.error(e);
      this.$router.push("/");
    } finally {
      store.commit("SET_LOAD_TEXT", "");
      store.commit("SET_LOADING", false);
    }
  }
}
